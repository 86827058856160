.add-date-content {
    display: flex;
    flex-direction: column;
    gap: 5px;
    justify-content: space-between;
    padding: 15px 0;
}

.add-date-content > div {
    margin: 0 10px;
}

.controls {
    display: flex;
    flex-direction: row-reverse;
    gap: 5px;
}

.controls > button[name='add'] {
    display: none;
}