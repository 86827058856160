.movable {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
}

.movable .title {
    font-size: calc(14px + 2vmin);
    flex: 1 1 auto;
    padding-left: 4px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}