.App {
  background-color: #282c34;
  color: white;
  font-size: calc(8px + 2vmin);
  max-width: 1280px;
  margin: 0 auto;
}

.App-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  text-align: center;
}

.App-link {
  color: #61dafb;
}

.month {
  padding-bottom: 60px;
}

.control-panel {
  background: transparent;
  bottom: 0;
  display: flex;
  flex-direction: row-reverse;
  gap: 10px;
  font-size: 100px;
  min-width: 1280px;
  margin: 0 auto;
  position: fixed;
}

.control-item {
  background-color: #282c34;
  border: 1px solid white;
  border-radius: 40px;
  cursor: pointer;
  font-size: 1em;
  height: 80px;
  line-height: 0.6em;
  margin: 30px 30px 30px 0;
  text-align: center;
  user-select: none;
  width: 80px;
}

.control-icon {
  height: 80px;
  width: 80px;
}

@media screen and (max-width: 1280px) {
  .control-panel {
    right: 0;
    width: 100%;
  }

  .control-item {
    font-size: 0.8em;
    border-radius: 30px;
    height: 60px;
    width: 60px;
  }

  .control-icon {
    height: 60px;
    width: 60px;
  }
}

/* add date modal */
.add-modal {
  background-color: black;
  color: white;
  position: absolute;
  top: 40%;
  left: calc(50% - 300px / 2);
  min-width: 300px;
}

.add-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
}
