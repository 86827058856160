.month-title {
    font-size: calc(24px + 2vmin);
    text-align: center;
    text-transform: capitalize;
    padding-top: 10px;
}

.day {
    border-bottom: 1px white solid;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 2px 10px;
    padding: 4px;
}

.day.red {
    color: red;
}

.day .date {
    border: 1px solid white;
    border-radius: 2px;
    min-width: 30px;
    padding: 3px;
    text-align: center;
}

.day .name {
    font-size: calc(4px + 2vmin);
}

.day .holiday {
    padding: 0 10px;
    vertical-align: top;
}

.date.crossed::before {
    content: '';
    position: absolute;
    height: calc(50px * 1.4);
    border-left: 2px solid red;
    transform: rotate(-38deg);
    transform-origin: 0 0;
    margin-left: -22px;
    margin-top: -2px;
    border-radius: 2px;
}
